import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import * as data from '../../assets/static.json';

@Injectable({
    providedIn: 'root'
})

export class WebApiHttp {

    public globalurl: string = data.url;

    public ApiURLArray: any = {

        // User URL Start

        login: '/api/User/Login',
        Logout: '/api/User/Logout',
        createUser: '/api/User/CreateUser',
        AllUser: '/api/User/AllUser?name=',
        roleProcess: '/api/Role/RoleProcess',
        signalRNotification: '/Notification',
        locationlist: '/api/User/LocationList',

        // User URL End


        //candidate start

        CandidateList: '/api/Candidate/CandidateList',
        CandidateCreateorUpdate: '/api/Candidate/CandidateCreateorUpdate',
        CandidateFullInfo: '/api/Candidate/CandidateFullInfo',
        //candidate end

        //Skill start

        SkillList: '/api/Skills/SkillList?filter=',

        //Skill end

        // Employees start

        EmployeesList: '/api/Employees/EmployeesList',
        DepartmentList: '/api/Employees/DepartmentList',
        DesignationList: '/api/Employees/DesignationList?department=',
        CreateEmployess: '/api/Employees/CreateEmployess',
        GetEmployeeID: '/api/Employees/GetEmployeeID',
        EmployeeFullInfo: '/api/Employees/EmployeeFullInfo?emp_id=',
        //Employees end


        //Interview start

        InterviewCreate: '/api/Interview/CreateInterview',
        InterviewList: '/api/Interview/InterviewList',
        InterviewResult: '/api/Interview/InterviewResult',

        //Interview end


        //Attendance start

        AttendanceList: '/api/Attendance/AttendanceList',
        Current: '/api/Attendance/Current',
        Start: '/api/Attendance/Start',
        End: '/api/Attendance/End',
        Delete: '/api/Attendance/Delete',

        //Attendance end

        //leave start
        LeaveTypeList: '/api/Leave/leave_type',
        LeaveList: '/api/Leave/LeaveList',
        AvailableLeave: '/api/Leave/AvailableLeave',
        ApplyLeave: '/api/Leave/ApplyLeave',
        LeaveForApproval: '/api/Leave/LeaveForApproval?emp_code=',
        LeaveApproval: '/api/Leave/LeaveApproval',


        //leave
        LeaveTypeCreate: '/api/Leave/LeaveTypeCreate',
        LeaveTypeDelete: '/api/Leave/LeaveTypeDelete?leave_code=',
        AppliedLeaveList: '/api/Leave/leave_approval_days',
        GetComprehensiveLeave: '/api/Leave/GetComprehensiveLeave?empid=',
        //leave end

        PrintSalary: '/api/Salary/PrintSalary',

        //apprasial start

        ListofAppraisal: '/api/AppraisalSetup/ListofAppraisal',
        AppraisalSeupCreate: '/api/AppraisalSetup/AppraisalSeupCreate',
        AppraisalSeupInfo: '/api/AppraisalSetup/AppraisalSeupInfo',

        //apprasial end

        // ta dabills
        getTravelDeatil: '/api/Travel/getTravelDeatil?flag=',
        insertTravelHeader: '/api/Travel/insertTravelHeader',
        getDistrictList: '/api/GeographicalSetup/getDistrictList',
        approveRejectTravel: '/api/Travel/approveRejectTravel',
        insertTravelExpense: '/api/Travel/insertTravelExpense',
        getCityMaster:'/api/Travel/CityMaster',
        //end tada

        //todo breeder visit
        breeder_visitor_get: '/api/BreederVisitor/breeder_visitor_get',
        breeder_visitor_insert: '/api/BreederVisitor/breeder_visitor_insert',
        item_get:'/api/Inspection/MyItemsUserWise?email=',
        zone_get:'/api/GeographicalSetup/Zone_mst_Get',
        season_get:'/api/DistributorMaster/Season_sync',
        lot_get:'/api/Inspection/ListForPlantinglot?season_code=',



        //todo land selection
        landSelectionInsert: '/api/LandSelection/landSelectionInsert',
        landSelectionGet: '/api/LandSelection/landSelectionGet',
        landSelectionUpdate: '/api/LandSelection/landSelectionUpdate',
        landSelectionDelete: '/api/LandSelection/landSelectionDelete',

        //todo YieldEstimate Api
        Yield_estimate_insert: '/api/YieldEstimate/Yield_estimate_insert',
        Yield_estimate_get: '/api/YieldEstimate/Yield_estimate_get?created_by=',
        Yield_estimate_get_lot_detail: '/api/Inspection/PlantingListLineLotNo?lot_no=',
        //todo YieldEstimate Api
        TrialSeedProduction_insert: '/api/TrialSeedProduction/TrialSeedProduction_insert',
        TrialSeedProduction_get: '/api/TrialSeedProduction/TrialSeedProduction_get?created_by=',
        Users_Location_Sync: '/api/Users_Location/Users_Location_Sync?user_ID=',
        Zone_mst_Get: '/api/GeographicalSetup/Zone_mst_Get',
        Area_mst_Get: '/api/GeographicalSetup/Area_mst_Get?zone_code=',
        MyItemsUserWiseWithParameter: '/api/Inspection/MyItemsUserWiseWithParameter?id=',

        //Daily Activiy
        daily_activity_get:'/api/DailyActivity/getDailyActivityDetail?email=',
        daily_activity_insert:'/api/DailyActivity/dailyActivityInsertHeaderLines',
        crop_and_variety_get:'/api/Inspection/MyItemsUserWise?email=',
        district_get:'/api/GeographicalSetup/GeographicalSetupData?lastSync=2021-06-19 11:47:53.557',
        distributorSync:'/api/DistributorMaster/distributor_sync?Customer_Type=',

        // Setup
        RoleMasterProcess: '/api/Role/RoleProcess',
        SaleInfoReport: '/api/Report/SaleOrder?source_no=',
        PickSetupAll: '/api/Pick/PickSetupAll',
        RolePermissionDetail: '/api/Role/RolePermissionDetail/',
        RolePermissionUpdate: '/api/Role/RolePermissionUpdate',
        UserRoleChange:'/api/Role/UserRoleChange',
        // UserSetup
        GetAllUser: '/api/User/allUser',
        CreateUser: '/api/User/createUser',
        UpdateUser: '/api/User/updateUser',
        GetAllWorktype: '/api/User/Worktype',
        UpdateUserPassword: '/api/User/UpdatePassword',
        AddPrinterIPaddress: '/api/User/AddIPandPort',

        //Inspection
        ZoneMasterGet:'/api/GeographicalSetup/zoneMasterProdGet',
        RegionMasterGet:'/api/GeographicalSetup/regionMasterProdGet',
        subzoneMasterGet:'/api/GeographicalSetup/subzoneMasterProdGet',
        DistrictMasterGet:'/api/GeographicalSetup/districtMasterProdGet',
        GeographicalMasterGet:'/api/GeographicalSetup/geographicalsetupProdGet',

        zoneUserNameUpdate :'/api/Inspection/zoneProdUserNameUpdate',
        RegionUserNameUpload:'/api/Inspection/regionProdUserNameUpdate',
        SubZoneUserNameUpdate:'/api/Inspection/subzoneProdUserNameUpdate',
        DistrictUserNameUpload:'/api/Inspection/districtProdUserNameUpdate',
    };
    constructor(private httpClient: HttpClient
    ) {

    }

    get getHTTPHeader(): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    getHTTPHeaderAuth(token: string): any {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };
    }

    // post data to server
    async Post(path: string, jsondata: any): Promise<any> {
        try {
            path = this.globalurl + path;
            var headers = this.getHTTPHeader;
            return await new Promise<any>((resolve, reject) => {
                this.httpClient.post<any>(path, JSON.stringify(jsondata), headers).toPromise()
                    .then(result => resolve(result), error => reject({
                        condition: 'False',
                        message: error.message
                    })).catch(err => reject({condition: 'False', message: err.message}));
            });

        } catch (e) {
            return new Promise<any>((resolve) => {
                resolve({condition: 'False', message: e.message});
            });
        }
    }

    // get data to the server
    async Get(path: string): Promise<any> {
        try {
            path = this.globalurl + path;
            var headers = this.getHTTPHeader;
            return await new Promise<any>((resolve, reject) => {
                this.httpClient.get<any>(path, headers).toPromise()
                    .then(result => resolve(result), error => reject({
                        condition: 'False',
                        message: error.message
                    })).catch(err => reject({condition: 'False', message: err.message}));
            });
        } catch (e) {
            return new Promise<any>((resolve) => {
                resolve({condition: 'False', message: e.message});
            });
        }
    }

    // For formdata
    async PostFormData(path: string, formdata: any): Promise<any> {
        try {
            path = this.globalurl + path;
            var header = this.getHTTPHeader;
            return await new Promise<any>((resolve, reject) => {
                this.httpClient.post<any>(path, formdata).toPromise()
                    .then(result => resolve(result), error => reject({
                        condition: 'false',
                        message: error.message
                    })).catch(error => reject({
                    condition: 'false',
                    message: error.message
                }));
            });

        } catch (e) {
            return new Promise<any>((resolve) => {
                resolve({condition: 'false', message: e.message});
            });
        }
    }

    // post data to server and get two type of response
    Post_Data_GetFile(path: string, jsondata: any) {
        path = this.globalurl + path;
        const request = new HttpRequest('POST', path, jsondata, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
        return this.httpClient.request(request);
    }

    Get_Data_With_DownloadStatus_GetFile(path: string) {
        path = this.globalurl + path;
        const request = new HttpRequest('GET', path, {
            responseType: 'blob',
            reportProgress: true,
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
        return this.httpClient.request(request);
    }

    blobToString(b) {
        var urldata, x;
        urldata = URL.createObjectURL(b);
        x = new XMLHttpRequest();
        x.open('GET', urldata, false); // although sync, you're not fetching over internet
        x.send();
        URL.revokeObjectURL(urldata);
        return x.responseText;
    }
}
